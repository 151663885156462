<template>
  <v-navigation-drawer
    :color="$vuetify.theme.dark ? '' : 'primary'"
    v-model="navigationDrawer"
    dark
    fixed
    app
  >
    <v-list-item class="pa-0 white">
      <v-card flat height="auto" class="pa-0 white d-flex align-center">
        <v-avatar tile width="100%" class="fix_padding" height="auto">
          <v-img style="object-fit: cover" :src="`${logo}`"></v-img>
        </v-avatar>
      </v-card>
    </v-list-item>

    <v-divider />

    <v-list dense>
      <v-list-item-group v-model="value" active-class="active" color="accent">
        <v-list-item
          v-for="(item, idx) in items"
          :key="idx"
          link
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      logo: "https://img2.gratispng.com/20181202/hvw/kisspng-goal-logo-clip-art-product-company-interstate-consulting-services-5c03d17c17dce5.0652273715437541080978.jpg",
      value: 1,
      items: null,
    };
  },
  computed: {
    navigationDrawer: {
      get: function() {
        return this.$vuetify.breakpoint.mobile ? this.drawer : !this.drawer
      },
      set: function(newvalue) {
        if(this.$vuetify.breakpoint.mobile && newvalue == false) {
          this.$emit('toggleDrawer', newvalue)
        }
      }
    }
  },
  mounted() {
    if (this.$cookies.get("system_client_id") != "null") {
      this.items = [
        { title: "Início", icon: "mdi-chart-arc", route: "/dashboard" },
        { title: "Imóveis", icon: "mdi-home-city", route: "/properties" },
        { title: "Clientes", icon: "mdi-emoticon-excited", route: "/clients" },
        { title: "Proprietários", icon: "mdi-account-key", route: "/owners" },
        {
          title: "Usuários",
          icon: "mdi-card-account-details",
          route: "/users",
        },
      ];
    } else {
      this.items = [
        { title: "Início", icon: "mdi-chart-arc", route: "admin/dashboard" },
        {
          title: "Clientes",
          icon: "mdi-emoticon-excited",
          route: "/admin/clients",
        },
        {
          title: "Usuários",
          icon: "mdi-card-account-details",
          route: "/admin/users",
        },
      ];
    }

    if (this.$cookies.get("logo") != null) {
      this.logo =
        "https://encode-index.s3.amazonaws.com/" + this.$cookies.get("logo");
    } else {
      this.logo =
        "https://img2.gratispng.com/20181202/hvw/kisspng-goal-logo-clip-art-product-company-interstate-consulting-services-5c03d17c17dce5.0652273715437541080978.jpg";
    }
  },
};
</script>

<style scoped>
.active::before {
  background: none;
}
.fix_padding {
  padding: 10% 2% 10% 2%;
}
</style>